<template>
    <div>
        <CRow style="margin-top: 30px">
            <CCol sm="1">
            </CCol>
            <CCol sm="7">
                <CInput
                    :label="$t('label.TITLE_PRELIMINAR_FILE')"
                    addLabelClasses="text-right"
                    disabled
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                    v-uppercase
                    :placeholder="$t('label.name')"
                    v-model="EdiFileName"
                    required 
                >
                </CInput>
            </CCol>
            <CCol sm="4" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="excel"
                    class="ml-2"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                </CButton>
                <CButton
                    color="info"
                    class="ml-2"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file"/>&nbsp; CSV
                </CButton>
                <CButton 
                    @click="desactivar()" 
                    color="wipe"
                    class="ml-2"
                    :disabled="apiStateLoading"
                >
                    <CIcon name="returnSolid" />
                    <span class="mx-2">{{$t('button.return')}}</span>
                </CButton>
            </CCol>
            <br />
            <CCol sm="12" class="mt-5">
                <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    :loading="apiStateLoading"
                    column-filter
                    :table-filter="tableTextHelpers.tableFilterText"
                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                    :items="computedItems"
                    :fields="fields"
                    :noItemsView="tableTextHelpers.noItemsViewText"
                    :items-per-page="5"
                    pagination
                >
                    <template #id="{index}">
                        <td class="center-cell text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <!--template slot="under-table">
                        <div v-if="computedItems.length !== 0">
                            <h6>{{$t('label.totalQuantity')+': '+computedItems.length}}</h6>
                        </div>
                    </template-->
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import Reportes from '@/_mixins/reportes';
    /*import {saveAs} from "file-saver";
    import Excel from "exceljs/dist/es5/exceljs.browser";*/
    import { DateFormater } from '@/_helpers/funciones';
    //data
    function data() {
        return {

        }
    }
    //methods
    function desactivar(){
        this.$store.state.planificacionestiba.collapsePreliminar = false;
    }
    //computed
    function fields(){
        return [
            { key: 'RowNumber', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"center-cell text-left", _style:'min-width:120px;text-align: left;'},
            { key: 'Size', label: this.$t('label.size')+' (FT)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal1', label: this.$t('label.Seal')+' 1',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal2', label: this.$t('label.Seal')+' 2',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal3', label: this.$t('label.Seal')+' 3',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal4', label: this.$t('label.Seal')+' 4',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Status', label: this.$t('label.status'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'ShippingLineCode', label: this.$t('label.carrier'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'ImdgCode', label: 'IMDG',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'LoadPort', label: 'POL',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'DischargePort', label: 'POD',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'DeliveryPort', label: 'PODEL',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'TranshipmentPort', label: 'POT',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Weigth', label: this.$t('label.weight')+' (KGM)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Lenght', label: this.$t('label.length')+' (KGM)',_classes:"center-cell"},
            { key: 'Height', label: this.$t('label.height')+' (KGM)',_classes:"center-cell"},
            { key: 'Vgm', label: 'VGM (KGM)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'VgmCertificate', label: this.$t('label.certificateVGM'),_classes:"center-cell text-left", _style:'min-width:120px;text-align: left;'},
            { key: 'Origin', label: this.$t('label.Origin'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Class', label: this.$t('label.class'),_classes:"center-cell", _style:'min-width:100px;'},
        ]
    }
    function EdiFileName() {
        return this.myDataPreliminarJson && this.myDataPreliminarJson.EdiFileName
        ? `${this.myDataPreliminarJson.EdiFileName}`
        : '';
    }
    async function onBtnExport(valor){
        let arreglo = [];
        let i = 1;
        //try{
            if(this.computedItems.length !== 0){
                this.computedItems.forEach(function (item) {
                    arreglo.push({
                        '#': i++,
                        'CONTAINER CODE': item.ContainerCode ? item.ContainerCode : "",
                        'SIZE (FT)': item.TpCargoCode ? item.TpCargoCode : 0,
                        'STATUS': item.Status ? item.Status : "",
                        'ISO CODE': item.IsoCode ? item.IsoCode : "",
                        'SEAL 1': item.Seal1 ? item.Seal1 : "",
                        'SEAL 2': item.Seal2 ? item.Seal2 : "",
                        'SEAL 3': item.Seal3 ? item.Seal3 : "",
                        'SEAL 4': item.Seal4 ? item.Seal4 : "",
                        'CARRIER': item.ShippingLineCode ? item.ShippingLineCode : "",
                        'IMDG': item.ImdgCode ? item.ImdgCode : "",
                        POL: item.LoadPort ? item.LoadPort : "",
                        POD: item.DischargePort ? item.DischargePort : "",
                        PODEL: item.DeliveryPort ? item.DeliveryPort : "",
                        POT: item.TranshipmentPort ? item.TranshipmentPort : "",
                        'WEIGHT (KGM)': item.Weigth ? item.Weigth : 0,
                        'VGM (KGM)': item.Vgm ? item.Vgm : 0,
                        'VGM CERTIFICATE': item.VgmCertificate ? item.VgmCertificate : "", 
                        BLNRO: item.BlNo ? item.BlNo : '',
                        'DICE CONTENER': item.GeneralInfo ? item.GeneralInfo : '',
                        'CONSIGNE': item.Consignee ? item.Consignee : '',
                        'U.M REEFER': item.UnitMeasureReefer ? item.UnitMeasureReefer : '',
                        'TEMPERATURE': item.ReeferSetting ? item.ReeferSetting : '',
                        'U.M OVERSIZE': item.UnitMeasureOversize ? item.UnitMeasureOversize : '',
                        'DIM FRONT': item.DimFront ? item.DimFront : '',
                        'DIM BACK': item.DimBack ? item.DimBack : '',
                        'DIM LEFT': item.DimLeft ? item.DimLeft : '',
                        'DIM RIGHT': item.DimRight ? item.DimRight : '',
                        'DIM TOP': item.DimTop ? item.DimTop : '',
                        'HEADING': item.HeadingName ? item.HeadingName : '',
                        'DUA': item.Dua ? item.Dua : '',
                    }); 
                });
                
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                await this.getExcelArray(arreglo,this.$t('label.preliminarList'),valor);

                /*const workbook = new Excel.Workbook();
                const worksheet = workbook.addWorksheet("PreliminarList");

                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);

                let dataBuque = {
                    DatePlanning:'',
                    CountryName:'',
                    VesselFlagRoute:'',
                }

                if(Index > -1){
                    dataBuque.DatePlanning = this.myDataItinirary[Index].DatePlanning;
                    dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                    dataBuque.VesselFlagRoute = this.myDataItinirary[Index].VesselFlagRoute;

                    worksheet.mergeCells('A1:A1');
                    const response1 = await this.$http.getFile('base64', {
                        path:this.user.LogoRoute.replace('Public/','', null, 'i')
                    });
                    const divisiones1 = this.user.LogoRoute.split(".");
                    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
                    const imageId = workbook.addImage({
                      base64: myBase64Image,
                      extension: divisiones1[1],
                    });
                    worksheet.addImage(imageId, 'A1:A1');

                    const rowIndex1 = worksheet.getRow(1);
                    rowIndex1.height = 100;

                    worksheet.mergeCells("B1:P1");

                    const customCell = worksheet.getCell("B1");
                    const row = worksheet.getRow(1);
                    row.height = 60;
                    customCell.font = {
                        name: "Calibri",
                        family: 4,
                        size: 12,
                        underline: false,
                        bold: true
                    };
                    customCell.note = {
                        margins : {
                            insetmode: 'auto',
                            inset: [10, 10, 5, 5]
                        }
                    }
                    customCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{
                            argb:'f2f2f2' 
                        }
                    }
                    customCell.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell.value = dataBuque.DatePlanning;
                }

                //COLUMNA 2
                worksheet.mergeCells("A2:H2");
                const customCell2 = worksheet.getCell("A2");
                customCell2.height = 50;
                customCell2.font = {
                    name: "Calibri",
                    family: 4,
                    size: 18,
                    underline: false,
                    bold: true
                };
                customCell2.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
                customCell2.value = this.EdiFileName;
                customCell2.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                }

                //columna 3
                worksheet.mergeCells("I2:L2");
                const customCell3 = worksheet.getCell("I2");
                customCell3.height = 50;
                customCell3.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell3.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                const hoy = new Date();
                customCell3.alignment = { vertical: 'middle', horizontal: 'center' }
                customCell3.value = "IMPRESIÓN:  "+hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                customCell3.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                }

                //columna 4
                worksheet.mergeCells("M2:P2");
                const customCell4 = worksheet.getCell("M2");
                customCell4.height = 50;
                customCell4.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell4.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
                customCell4.value = "LOGIN DE USUARIO: "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
                customCell4.border = {
                    top: {style:'thin'},
                    right: {style:'thin'},
                    bottom: {style:'thin'},
                }

                var headerRow = worksheet.addRow();
                headerRow.font =  {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };
                headerRow.height = 30;

                let keyColumns = [];
                this.fields.map(async(e) =>{
                    keyColumns.push(e.key);
                });
                let i = 0;
                this.fields.map(async (data) => {
                    let valor = data.label.length;
                    let long = 10;
                    if ((valor>5) && (valor<12)) {
                        long = 15;
                    } else if ((valor>11) && (valor<18)) {
                        long = 20;
                    } else if ((valor > 19) && (valor < 26)) {
                        long = 25;
                    } else if (valor>27) {
                        long = 35;
                    }

                    worksheet.getColumn(i + 1).width = long;
                    let cell = headerRow.getCell(i + 1);

                    
                    cell.border = {
                        top: {style:'thin'},
                        bottom: {style:'thin'},
                    }
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    
                    cell.value = data.label;
                    cell.alignment = { vertical: 'middle', horizontal: 'center' }
                    i++;
                });

                this.computedItems.map(async function(data){
                    let dataRow = worksheet.addRow();
                    let indice = 0;
                    keyColumns.map(function(data2, index2){
                        let cell = dataRow.getCell(indice + 1);
                        if(typeof data[data2] === 'boolean'){
                            cell.value = data[data2] ? 'YES' : 'NO';
                        }else{
                            cell.value = data[data2];
                        }
                        cell.alignment = { vertical: 'middle', horizontal: 'center' }
                        indice++;
                    })
                })

                // save under export.xlsx
                workbook.xlsx.writeBuffer()
                .then(buffer => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    let hoy = new Date();
                    let time = hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
                    let today = DateFormater.formatOnlyDateWithSlash(new Date());
                    today = today.replaceAll("/", "");
                    let titulo = (`${this.EdiFileName}${today}${time}.xlsx`).replaceAll(':','');
                    saveAs(new Blob([buffer]), titulo)
                    this.$notify({
                        group: 'container',
                        text: 'Formato Generado!',
                        type: "success"
                    });
                })
                .catch(err => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    console.log('Error writing excel export', err)
                })
            }*/
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            
            }
        /*} catch (error) {
            console.log(error);
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            this.$store.commit('planificacionestiba/messageMutation', error);
        }*/
    }
    /*async function onBtnCsv(){
        if(this.computedItems.length !== 0){
            try{
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;

                const workbook = new Excel.Workbook();
                const worksheet = workbook.addWorksheet("ContainerList");
                var headerRow = worksheet.addRow();

                let keyColumns = [];
                this.fields.map(async(e) =>{
                    keyColumns.push(e.key);
                });
                let i = 0;
                this.fields.map(async (data) => {
                    let width = data.headerName ? data.headerName.toString().length : 10;

                    worksheet.getColumn(i + 1).width = width < 10 ? 15 : width*2;
                    let cell = headerRow.getCell(i + 1);

                    cell.alignment = { vertical: 'middle', horizontal: 'center' }
                    i++;
                });

                this.computedItems.map(async function(data){
                    let dataRow = worksheet.addRow();
                    let indice = 0;
                    keyColumns.map(function(data2, index2){
                        let cell = dataRow.getCell(indice + 1);
                        if(typeof data[data2] === 'boolean'){
                            cell.value = data[data2] ? 'YES' : 'NO';
                        }else{
                            cell.value = data[data2];
                        }
                        
                        indice++;
                    })
                })

                // save under export.xlsx
                workbook.csv.writeBuffer()
                .then(buffer => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    let hoy = new Date();
                    let time = hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
                    let today = DateFormater.formatOnlyDateWithSlash(new Date());
                    today = today.replaceAll("/", "");
                    let titulo = (`${this.EdiFileName}${today}${time}.csv`).replaceAll(':','');
                    saveAs(new Blob([buffer]), titulo)
                    this.$notify({
                        group: 'container',
                        text: 'Formato Generado!',
                        type: "success"
                    });
                }).catch(err => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    console.log('Error writing excel export', err)
                })
            }catch (error) {
                console.log(error);
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.commit('planificacionestiba/messageMutation', error);
            }
        }
    }*/
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function computedItems () {
        return this.$store.getters["planificacionestiba/myDataPreliminartable"];
    }
    export default {
        name:'review-preliminar-list',
        mixins: [Reportes],
        data,
        methods:{
            desactivar,
            onBtnExport
        },
        directives: UpperCase,
        computed:{
            EdiFileName,
            fields,
            computedItems,
            tableTextHelpers,
            apiStateLoading,
            ...mapState({
                user: state => state.auth.user,
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                planificacionId: state => state.planificacionestiba.planificacionId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                myDataPreliminarJson: state => state.planificacionestiba.myDataPreliminarJson,
            })
        }
    }
</script>